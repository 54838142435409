import { colours, fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import hexToRgb from 'src/utils/hexToRgb';

const baseStyle = {
  color: colours.white,
  fontFamily: fonts.bold,
  textShadow: '0px 0px 2px rgba(0, 0, 0, 0.27)',
  iconSpacing: '2px',
  fontWeight: 'normal !important',
};

const Button = {
  baseStyle,
  sizes: {
    xs: {
      fontSize: '12px',
      lineHeight: '18px',
      h: 'unset',
      px: '10px',
      py: '3px',
      borderRadius: '6px',
    },
    sm: {
      fontSize: '14px',
      lineHeight: '18px',
      h: 'unset',
      px: '16px',
      py: '9px',
      borderRadius: '8px',
    },
    md: {
      fontSize: '15px',
      lineHeight: '24px',
      h: 'unset',
      px: '20px',
      py: '9px',
      borderRadius: '8px',
    },
    lg: {
      fontSize: '17px',
      lineHeight: '24px',
      h: 'unset',
      px: '24px',
      py: '12px',
      borderRadius: '12px',
    },
    xl: {
      fontSize: '17px',
      lineHeight: '24px',
      h: 'unset',
      px: '30px',
      py: '15px',
      borderRadius: '12px',
    },
  },
  variants: {
    primary: {
      ...baseStyle,
      background: `linear-gradient(${colors.podcastPurple} 0%, ${colors.podcastPurple800} 77.08%)`,
      _hover: {
        background: `linear-gradient(${colors.podcastPurple800} 0%, ${colors.podcastPurple800} 100%)`,
        _disabled: {
          bgGradient: 'linear(to-b, podcastPurple, podcastPurple800)',
        },
      },
    },
    secondary: {
      ...baseStyle,
      bg: `linear-gradient(${colors.podcastBlue} 0%, ${colors.podcastBlue600} 82.81%)`,
      _hover: {
        background: `linear-gradient(${colors.podcastBlue600} 0%, ${colors.podcastBlue600} 100%)`,

        _disabled: {
          bgGradient: 'linear(to-b, podcastBlue, podcastBlue600)',
        },
      },
    },
    destructive: {
      ...baseStyle,
      bg: `linear-gradient(${colors.podcastRed600} 0%, ${colors.podcastRed} 77.08%)`,
      _hover: {
        background: `linear-gradient(${colors.podcastRed} 0%, ${colors.podcastRed} 100%)`,
        _disabled: {
          bgGradient: 'linear(to-b, podcastRed600, podcastRed)',
        },
      },
    },
    tertiary: {
      ...baseStyle,
      color: 'podcastShade700',
      textShadow: 'none',
      transition: '200ms ease-in',
      bg: colours.white,
      border: `1px solid ${colors.podcastShade300}`,
      boxShadow: `inset 0px 0px 0px 0px ${colors.podcastShade300}, inset 0px -2px 0px ${colors.podcastShade300}, inset 0px -3px 10px ${colors.podcastShade200}`,
      _hover: {
        boxShadow: `inset 0px -2px 0px ${colors.podcastShade300}`,
      },
    },
    quaternary: {
      ...baseStyle,
      background: colors.podcastPurple100,
      color: colors.podcastPurple,
      textShadow: 'none',
      _hover: {
        opacity: 0.8,
        _disabled: {
          opacity: 0.5,
        },
      },
    },
    orange: {
      ...baseStyle,
      bg: 'AlituOrange',
      bgGradient: 'linear(to-b, AlituOrange, AlituOrangeDark)',
      _hover: {
        _disabled: {
          bgGradient: 'linear(to-b, AlituOrange, AlituOrangeDark)',
        },
      },
    },
    input: {
      bg: 'podcastPurple800',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingInline: '24px',
      height: '48px',
      bgGradient: 'linear(to-b, podcastPurple, podcastPurple800)',
      _hover: {
        _disabled: {
          bgGradient: 'linear(to-b, podcastPurple, podcastPurple800)',
        },
      },
    },
    empty: {
      bg: 'none',
      borderRadius: 0,
      margin: 0,
      padding: 0,
      textShadow: 'none',
      color: 'podcastShade500',
    },
    flat: {
      boxShadow: 'none',
      textTransform: 'uppercase',
      textShadow: 'none',
      _hover: { bgColor: 'podcastPurple' },
      _active: { bgColor: 'podcastPurple800' },
    },
    'mini-player': {
      bgGradient: 'none',
      boxShadow: 'none',
      bgColor: hexToRgb(colors.podcastPurple, 0.1),
      _hover: { bgColor: hexToRgb(colors.podcastPurple, 0.05) },
      _active: { bgColor: hexToRgb(colors.podcastPurple, 0.15) },
    },
    file: {
      height: '30px',
      fontSize: '13px',
      lineHeight: '18px',
      textShadow: 'none',
      fontFamily: fonts.bold,
      borderRadius: '4px',
      cursor: 'initial',
    },
    help: {
      bgGradient: 'none',
      boxShadow: 'none',
      bgColor: hexToRgb(colors.podcastPurple, 0.1),
      _hover: { bgColor: hexToRgb(colors.podcastPurple, 0.05) },
      _active: { bgColor: hexToRgb(colors.podcastPurple, 0.15) },
      color: 'podcastPurple',
      textShadow: 'none',
    },
    cutTool: {
      borderRadius: '10px',
      bgColor: 'podcastRed600',
      _hover: { bgColor: 'podcastRed400' },
      _active: { bgColor: 'podcastRed' },
      px: '10px',
      py: '3px',
    },
    clipTool: {
      borderRadius: '10px',
      bgColor: 'podcastPurple',
      _hover: { bgColor: 'podcastPurple500' },
      _active: { bgColor: 'podcastPurple800' },
      px: '10px',
      py: '3px',
    },
    silenceTool: {
      borderRadius: '10px',
      bgColor: 'podcastBlue600',
      _hover: { bgColor: 'podcastBlue' },
      _active: { bgColor: 'podcastBlue700' },
      px: '10px',
      py: '3px',
    },
    bleepTool: {
      borderRadius: '10px',
      bgColor: 'podcastOrange',
      _hover: { bgColor: 'podcastOrange500' },
      _active: { bgColor: 'podcastOrange300' },
      px: '10px',
      py: '3px',
    },
    alituWavesTool: {
      w: '66px',
      bg: 'white',
      borderRadius: '0px',
    },
    darkGrey: {
      borderRadius: '12px',
      bgColor: 'podcastShade600',
      _hover: { bgColor: 'podcastShade500' },
      _active: { bgColor: 'podcastShade700' },
      p: '16px',
      h: '36px',
      w: '36px',
      minW: '36px',
    },
    transcriptEditMarker: {
      bg: colors.podcastShade200,
      color: colors.podcastShade500,
      textShadow: 'none',
      fontSize: '13px',
      lineHeight: '18px',
      h: 'unset',
      px: '4px',
      py: '0',
      borderRadius: '6px',
    },
    link: {
      bg: 'none',
      color: 'podcastPurple',
      textShadow: 'none',
      textDecoration: 'underline',
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
};

export default Button;
